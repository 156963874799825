<template>
  <div class="edrea_tm_modalbox" :class="open ? 'opened' : ''">
    <div class="box_inner">
      <div class="close">
        <a href="#" @click="close"><i class="icon-cancel"></i></a>
      </div>
      <div class="description_wrap">
        <div class="about_popup_details">
          <div class="left">
            <div class="left_inner">
              <div class="author">
                <div class="avatar_image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div class="main" data-img-url="img/about/1.jpg"></div>
                </div>
                <div class="short">
                  <h3 class="name">
                    {{ aboutData.firstName }}
                    <span class="coloring">{{ aboutData.lastName }}</span>
                  </h3>
                  <h3 class="job">
                    <AnimationText />
                  </h3>
                </div>
              </div>
              <div class="list">
                <ul>
                  <li>
                    <div class="list_inner">
                      <i class="icon-user"></i>
                      <span>{{ aboutData.firstName }}
                        {{ aboutData.lastName }}</span>
                    </div>
                  </li>
                  <!-- <li>
                    <div class="list_inner">
                      <i class="icon-calendar"></i>
                      <span>{{ aboutData.bithday }}</span>
                    </div>
                  </li> -->
                  <li>
                    <div class="list_inner">
                      <i class="icon-location"></i>
                      <span><a href="#" class="href_location">{{
                        aboutData.address
                      }}</a></span>
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <i class="icon-phone"></i>
                      <span><a href="#">{{ aboutData.phn }}</a></span>
                    </div>
                  </li>
                  <li>
                    <div class="list_inner" style="width: 125%;">
                      <i class="icon-mail-1"></i>
                      <span><a :href="`mailto:${aboutData.email}`">{{
                        aboutData.email
                      }}</a></span>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="edrea_tm_button full">
                <a href="./img/Hyder Ali Maroof - CV.pdf" download>Download CV</a>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="right_inner">
              <div class="biography">
                <div class="about_title">
                  <h3>
                    <span>About <span class="coloring">Me</span></span>
                  </h3>
                </div>
                <div class="text">
                  <p>
                    Hello, I bring a wealth of experience across diverse domains, spanning from
                    software development to comprehensive system administration on both Linux and Windows platforms. My
                    expertise extends to full-stack application development, proficient database management, and the
                    seamless orchestration of day-to-day IT operations. My skills encompass a broad spectrum, including
                    DHCP, DNS, IIS, Apache, Active Directory, and network design. In my current capacity as a Global IT
                    Manager, I oversee operations in Zambia, Burkina Faso, Uganda, Germany, and Pakistan. This role
                    demands a strategic approach to IT management, ensuring seamless coordination and optimal performance
                    across international teams and diverse technological landscapes
                  </p>
                </div>
              </div>

              <div class="prog_skill">
                <div class="about_title">
                  <h3>
                    <span>Programming <span class="coloring">Skills</span></span>
                  </h3>
                </div>
                <div class="oki_progress">
                  <div class="progress_inner" v-for="(skill, i) in aboutData.skills.programming" :key="i">
                    <span><span class="label">{{ skill.name }}</span><span class="number">{{ skill.value }}%</span></span>
                    <div class="background">
                      <div class="bar open">
                        <div class="bar_in" :style="{ width: `${skill.value}%` }"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="lang_skill">
                <div class="about_title">
                  <h3>
                    <span>Framework <span class="coloring">Skills</span></span>
                  </h3>
                </div>
                <div class="circular_progress_bar">
                  <ul>
                    <li>
                      <div class="list_inner">
                        <div class="myCircle" data-value=".95"></div>

                        <div class="title"><span>Django</span></div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <div class="myCircle" data-value="0.8"></div>

                        <div class="title"><span>Laravel</span></div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <div class="myCircle" data-value="0.7"></div>

                        <div class="title"><span>VueJS</span></div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <div class="myCircle" data-value="0.8"></div>

                        <div class="title"><span>Flask</span></div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <div class="myCircle" data-value="0.75"></div>

                        <div class="title"><span>ASP.net</span></div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="prog_skill">
                <div class="about_title">
                  <h3>
                    <span>Database <span class="coloring">Skills</span></span>
                  </h3>
                </div>
                <div class="oki_progress">
                  <div class="progress_inner" v-for="(skill, i) in aboutData.skills.database" :key="i">
                    <span><span class="label">{{ skill.name }}</span><span class="number">{{ skill.value }}%</span></span>
                    <div class="background">
                      <div class="bar open">
                        <div class="bar_in" :style="{ width: `${skill.value}%` }"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="service">
                <div class="about_title">
                  <h3>
                    <span>Other <span class="coloring">Skills</span></span>
                  </h3>
                </div>
                <div class="list">
                  <ul>
                    <li v-for="(service, i) in aboutData.serviceLists" :key="i">
                      <i class="icon-right-dir"></i>{{ service }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="timeline">
                <div class="about_title">
                  <h3>
                    <span>Education <span class="coloring">Timeline</span></span>
                  </h3>
                </div>
                <div class="list">
                  <ul>
                    <li v-for="(edu, i) in aboutData.education" :key="i">
                      <div class="list_inner">
                        <div class="time">
                          <span>{{ edu.year }}</span>
                        </div>
                        <div class="place">
                          <h3>{{ edu.unv }}</h3>
                          <span>{{ edu.degree }}</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="timeline">
                <div class="about_title">
                  <h3>
                    <span>Working <span class="coloring">Timeline</span></span>
                  </h3>
                </div>
                <div class="list">
                  <ul>
                    <li v-for="(work, i) in aboutData.working" :key="i">
                      <div class="list_inner">
                        <div class="time">
                          <span>{{ work.year }}</span>
                        </div>
                        <div class="place">
                          <h3>{{ work.company }}</h3>
                          <span>{{ work.deg }}</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- <div class="partners">
                <div class="about_title">
                  <h3>
                    <span>My <span class="coloring">Partners</span></span>
                  </h3>
                </div>
                <div class="list">
                  <ul>
                    <li v-for="(partner, i) in aboutData.partnersLogos">
                      <div class="list_inner">
                        <img :src="partner" alt="" />
                        <a class="cavani_tm_full_link" href="#"></a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="testimonial">
                <div class="about_title">
                  <h3>
                    <span>Clients <span class="coloring">Feedback</span></span>
                  </h3>
                </div>
                <div class="list">
                  <swiper :loop="true" :slidesPerView="1" :spaceBetween="10" :loopAdditionalSlides="1" :autoplay="{
                    delay: 6000,
                  }" :modules="modules" class="owl-carousel">
                    <swiper-slide>
                      <div class="list_inner">
                        <div class="text">
                          <i class="icon-quote-left"></i>
                          <p>
                            Beautiful minimalist design and great, fast response
                            with support. Highly recommend. Thanks Marketify!
                          </p>
                        </div>
                        <div class="details">
                          <div class="image">
                            <div class="main" data-img-url="img/testimonials/1.jpg"></div>
                          </div>
                          <div class="info">
                            <h3>Alexander Walker</h3>
                            <span>Graphic Designer</span>
                          </div>
                        </div>
                      </div>
                    </swiper-slide>
                    <swiper-slide>
                      <div class="list_inner">
                        <div class="text">
                          <i class="icon-quote-left"></i>
                          <p>
                            These people really know what they are doing! Great
                            customer support availability and supperb kindness.
                          </p>
                        </div>
                        <div class="details">
                          <div class="image">
                            <div class="main" data-img-url="img/testimonials/2.jpg"></div>
                          </div>
                          <div class="info">
                            <h3>Armin Van Buuren</h3>
                            <span>Content Manager</span>
                          </div>
                        </div>
                      </div>
                    </swiper-slide>
                    <swiper-slide>
                      <div class="list_inner">
                        <div class="text">
                          <i class="icon-quote-left"></i>
                          <p>
                            I had a little problem and the support was just
                            awesome to quickly solve the situation. And keep
                            going on.
                          </p>
                        </div>
                        <div class="details">
                          <div class="image">
                            <div class="main" data-img-url="img/testimonials/3.jpg"></div>
                          </div>
                          <div class="info">
                            <h3>Baraka Clinton</h3>
                            <span>English Teacher</span>
                          </div>
                        </div>
                      </div>
                    </swiper-slide>
                  </swiper>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import AnimationText from "../AnimationText.vue";
export default {
  name: `AboutPopup`,
  components: {
    Swiper,
    SwiperSlide,
    AnimationText,
  },
  data() {
    return {
      open: false,
      // data
      aboutData: {
        firstName: "Hyder",
        lastName: "Ali Maroof",
        address: "Islamabad, Pakistan",
        phn: "+92 317 204 65 87",
        email: "hyderalimaroof@gmail.com",
        serviceLists: [
          "Redis",
          "Celery",
          "Stripe",
          "Linux",
          "Docker",
          "Nginx",
          "Apache",
          "Excel",
          "Office 365",
          "Networking",
          "Desktop support",
          "Installations",
          "Wrike/Zoho",
        ],
        skills: {
          programming: [
            { name: "Python", value: "90" },
            { name: "PHP", value: "75" },
            { name: "Javascript", value: "70" },
            { name: "c#", value: "60" },
          ],
          database: [
            { name: "MySQL", value: "95" },
            { name: "MariaDB", value: "90" },
            { name: "PostgreSQL", value: "90" },
          ],
        },
        education: [
          {
            year: "2017 - 2019",
            unv: "Comprehensive College",
            degree: "I-COM",
          },
          {
            year: "2019 - 2020",
            unv: "Aptech",
            degree: "ACCP Prime",
          },
          {
            year: "2020",
            unv: "OnWire",
            degree: "CCNA",
          },
          {
            year: "2023 - In-progress",
            unv: "Virtual University Of Pakistan",
            degree: "ADP-Computer Science",
          },
        ],
        working: [
          {
            year: "2023 - Present",
            company: "C4ED | Islamabad",
            deg: "Global IT Manager",
          },
          {
            year: "2021 - 2023",
            company: "Devroute | Karachi",
            des:"I am responsible for efficiently addressing and resolving a wide range of software-related issues that may arise within the organization. This includes troubleshooting, providing technical support, and ensuring the smooth operation of software applications to enhance overall productivity and user satisfactionDeveloped an automation script for repetitive tasks and configured Zoho CRM to boost operational efficiency and enhance user experience",
            deg: "Team Lead",
          },
          {
            year: "2020 - 2021",
            company: "STEXITY | Karachi",
            deg: "Full-Stack Engineer",
          },
          {
            year: "2019 - 2020",
            company: "NASTECH | Karachi",
            deg: "System Administrator",
          },
        ],
        partnersLogos: [
          "img/partners/1.png",
          "img/partners/2.png",
          "img/partners/3.png",
          "img/partners/4.png",
        ],
      },
    };
  },
  methods: {
    close() {
      this.open = false;
    },
  },
  mounted() {
    const aboutPopup = document.getElementById("aboutPopup");
    aboutPopup.addEventListener("click", () => {
      this.open = true;
    });

    const myCircle = document.querySelectorAll(".myCircle");
    myCircle.forEach((circle) => {
      let progress = circle.getAttribute("data-value");
      let span = document.createElement("span");
      span.className = "number";
      const value = `${progress * 100}%`;
      span.innerHTML = value;
      circle.appendChild(span);
      circle.style.setProperty("--progress", value);
      circle.setAttribute("aria-valuenow", value);
    });
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
    };
  },
};
</script>

<style scoped>
.testimonial {
  overflow: hidden;
}

.owl-carousel.swiper {
  overflow: unset;
}

.myCircle {
  --hue: 220;
  --holesize: 67%;
  --track-bg: hsl(233, 34%, 92%);

  min-width: 110px;
  min-height: 110px;
  display: grid;
  align-items: center;
  justify-items: center;
  place-items: center;
  position: relative;
  margin-bottom: 5px;
}

.myCircle::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 50%;
  z-index: -1;
  background: conic-gradient(var(--main-color) var(--progress, 0%),
      rgba(255, 255, 255, 0.15) var(--progress, 0%) 100%);

  mask-image: radial-gradient(transparent var(--holesize),
      black calc(var(--holesize) + 1px));
}
</style>
